export default [
  {
    path: '/ed',
    name: 'ed',
    components: {
      default: () => import(/* webpackChunkName: "ed" */ '@/views/ed/RoutePage.vue'),
      tabs: () => import(/* webpackChunkName: "ed" */ '@/views/ed/RouteTabs.vue'),
    },
    meta: { isAuth: true },
    children: [
      {
        path: 'study-program',
        name: 'ed.study-program',
        component: () => import(/* webpackChunkName: "ed" */ '@/views/ed/StudyProgramPage.vue'),
        meta: { isAuth: true },
      },
      {
        path: 'student-book',
        name: 'ed.student-book',
        component: () => import(/* webpackChunkName: "ed" */ '@/views/ed/StudentBookPage.vue'),
        meta: { isAuth: true },
      },
      {
        path: 'rating',
        name: 'ed.rating',
        component: () => import(/* webpackChunkName: "ed" */ '@/views/ed/Rating.vue'),
        meta: { isAuth: true },
      },
      {
        path: 'schedule',
        name: 'ed.schedule',
        component: () => import(/* webpackChunkName: "ed" */ '@/views/ed/Schedule.vue'),
        meta: { isAuth: true },
      },
      {
        path: 'attendance',
        name: 'ed.attendance',
        component: () => import(/* webpackChunkName: "ed" */ '@/views/ed/Attendance.vue'),
        meta: { isAuth: true },
      },
    ],
  },
];
