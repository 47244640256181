import http from './http';

const baseUrl = (process.env.VUE_APP_API_SOCIAL || '').replace(/\/$/g, '');

export default {
  channel: {
    list: params => http.get(`${baseUrl}/channel`, { params }),
    find: ({ id }) => http.get(`${baseUrl}/channel/${id}`),
    create: data => http.post(`${baseUrl}/channel`, data),
    update: ({ id, ...data }) => http.patch(`${baseUrl}/channel/${id}`, data),
    delete: ({ id }) => http.delete(`${baseUrl}/channel/${id}`),
  },
};
