import IconOlvery from '@/components/icons/IconOlvery.vue';
import CompaniesAndPeople from '@/components/layout/menu/item/CompaniesAndPeople.vue';

export default function () {
  return [
    // user
    {
      title: this.$t('nav.user.home'),
      route: { name: 'home' },
      icon: IconOlvery,
      guard: 'profile:my-profile.read',
    },
    {
      title: this.$t('Обучение'),
      route: { name: 'ed' },
      icon: 'mdi-school-outline',
      guard: 'profile:my-profile.read',
      userIds: (process.env.VUE_APP_FAKE_ED_USERS || '').split(',').map(x => parseInt(x)),
    },
    {
      title: this.$t('nav.user.news'),
      route: { name: 'news' },
      icon: 'mdi-newspaper-variant-outline',
      guard: 'event:news.search',
    },
    {
      title: this.$t('nav.user.events'),
      route: { name: 'events' },
      icon: 'mdi-calendar-outline',
      guard: 'event:event.search',
    },
    {
      title: this.$t('nav.user.services'),
      route: { name: 'services.all' },
      icon: 'mdi-star-outline',
      guard: 'otrs:category.list',
    },
    {
      title: this.$t('nav.user.community'),
      route: { name: 'community' },
      icon: 'mdi-account-multiple-outline',
      guard: ['profile:company.list', 'profile:profile.list'],
    },
    // admin
    {
      title: this.$t('nav.admin.booking'),
      route: { name: 'admin.booking' },
      icon: 'mdi-calendar-clock',
      guard: [
        'otrs:conference.reservation.calendar',
        'otrs:coworking.admin.ticket.calendar',
        'otrs:hotel.admin.ticket.calendar',
        'otrs:conference.reservation.list',
        'otrs:coworking.admin.ticket.list',
        'otrs:hotel.admin.ticket.list',
      ],
    },
    {
      title: this.$t('nav.admin.store'),
      route: { name: 'admin.coffee-break' },
      icon: 'mdi-coffee-outline',
      guard: [
        'otrs:servicing.service-ticket-delivery.list',
        'otrs:coffee-break.admin.transaction.list',
        'otrs:menu.create',
        'otrs:coffee-break.admin.stock-product.list',
      ],
    },
    {
      title: this.$t('nav.admin.requests'),
      route: { name: 'admin.requests' },
      icon: 'mdi-forum-outline',
      guard: 'otrs:admin.service-ticket-with-execution.list',
    },
    {
      title: this.$t('nav.admin.media'),
      route: { name: 'admin.media' },
      icon: 'mdi-newspaper-variant-outline',
      guard: ['event:admin.news.search', 'event:admin.event.update'],
    },
    {
      title: this.$t('nav.admin.cap'),
      route: { name: 'admin.cap' },
      icon: 'mdi-folder-account-outline',
      guard: [
        'profile:admin.company.patch',
        'profile:admin.profile.list-show-admin',
        'profile:admin.member-request.list',
        'offer:offer.create',
      ],
      component: CompaniesAndPeople,
    },
    {
      title: this.$t('nav.admin.services'),
      route: { name: 'admin.services' },
      icon: 'mdi-shape-outline',
      guard: 'otrs:service.create',
    },
    {
      title: this.$t('nav.admin.ads'),
      route: { name: 'admin.ads' },
      icon: 'mdi-bullhorn-outline',
      guard: ['profile:admin.notification-job.search', 'otrs:promo.story.create'],
    },
    {
      title: this.$t('nav.admin.acs'),
      route: { name: 'admin.acs' },
      icon: 'mdi-lock-open-outline',
      guard: 'nfc:access-log.list',
    },
    {
      title: this.$t('Справочники'),
      icon: 'mdi-book-open-outline',
      route: { name: 'admin.ref' },
      guard: [
        'doc:template.update',
        'rbac:admin.role.update',
        'location:price-category.update',
        'location:counter.update',
        'event:admin.news-category.search',
        'event:admin.category.search',
        'acs:admin.access-level.list',
        'otrs:coworking.admin.work-place.create',
        'otrs:hotel.admin.room.create',
        'otrs:coffee-break.admin.product.list',
        'otrs:stock.create',
        'social:channel.create',
      ],
    },
    {
      title: this.$t('Приборы учета'),
      icon: 'mdi-counter',
      route: { name: 'admin.meter-readings' },
      guard: 'location:meter-reading.list',
    },
    {
      title: this.$t('nav.admin.registry'),
      icon: 'mdi-calculator-variant-outline',
      route: { name: 'admin.registry' },
      guard: [
        'otrs:registry.daily-payment.search',
        'otrs:registry.monthly-bill.search',
        'otrs:registry.daily-poster.search',
        'billing:accountant.payment.list',
        'doc:document.search',
      ],
    },
    {
      title: this.$t('nav.admin.staticPages'),
      icon: 'mdi-book-open-page-variant-outline',
      route: { name: 'admin.static-pages' },
      guard: 'auth:admin.static-page.list',
    },
    // operator
    {
      title: this.$t('nav.operator.locations'),
      route: { name: 'operator.locations.layout' },
      icon: 'mdi-map-marker-outline',
      guard: 'auth:admin.location.list',
    },
    {
      title: this.$t('nav.operator.access'),
      route: { name: 'operator.access' },
      icon: 'mdi-shield-lock-outline',
      guard: ['rbac:role.update', 'rbac:permission.update', 'offer:offer-template.update'],
    },
    {
      title: this.$t('nav.operator.microservices'),
      route: { name: 'rbac.microservices' },
      icon: 'mdi-docker',
      guard: 'rbac:micro-service.update',
    },
  ];
}
